import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Tooltip, Menu, Dropdown, Button, Checkbox, Typography } from "antd";
import { selectSelectedView, selectTableColumns } from "../store/selector";
import ACTIONS from "../store/action";
import ColumnSelectorIcon from "../assets/svg/ColumnSelectorIcon";
import { ColumnsKeys } from "../constant/columns";
import { selectedViews } from "../constant/table";
import "./ColumnSelector.css";
import { MainBucketsTrackEvents } from "../constant/filters";
import { useDashboardBucketsTrackingEvent } from "../utils/pendo/hooks";

const { Text } = Typography;

const ColumnSelectorComponent = ({ tableColumns, setTableColumns, selectedView, isSearchView = false }) => {
  const [visible, setVisible] = useState(false);
  const newSelectedView = isSearchView ? selectedViews.allPatients : selectedView || selectedViews.allPatients;
  const { trackEvent } = useDashboardBucketsTrackingEvent();

  const handleVisibleChange = (flag) => setVisible(flag);

  const handleMenuClick = (e) => {
    const currColumn = Object.values(tableColumns[newSelectedView])[e.key];
    const newColumn = { ...currColumn, display: !currColumn.display };
    const newTableColumns = {
      ...tableColumns,
      [newSelectedView]: {
        ...tableColumns[newSelectedView],
        [newColumn.key]: newColumn
      }
    };

    try {
      localStorage.setItem(
        ColumnsKeys.tables[newSelectedView],
        JSON.stringify({ ...tableColumns[newSelectedView], [newColumn.key]: newColumn })
      );
      newColumn?.display &&
        trackEvent(MainBucketsTrackEvents.BUCKET_COLUMN_SELECTOR_SELECTED, { columnName: newColumn.key });
    } catch (err) {
      console.error(`could not save column selection: ${err}`);
    }

    setTableColumns(newTableColumns);
  };

  const menu = () => (
    <Menu onClick={handleMenuClick}>
      {Object.values(tableColumns[newSelectedView]).map(
        (col, index) =>
          col.key !== "hasConflicts" &&
          col.key !== "actions" &&
          col.key !== "edit" &&
          col.key !== "star" && (
            <Menu.Item disabled={col.mandatory} key={index} id={`column_selector_${col.key}`}>
              <Checkbox disabled={col.mandatory} checked={col.display}>
                {col.title}
              </Checkbox>
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const { t } = useTranslation();
  return (
    <Tooltip title={<Text>{t("column_selector")}</Text>} color={"white"}>
      <Dropdown overlay={menu()} trigger="click" onOpenChange={handleVisibleChange} open={visible}>
        <Button
          id="column_selector"
          style={{ bottom: 6, marginRight: 5 }}
          type="text"
          onClick={(e) => e.preventDefault()}
          icon={<ColumnSelectorIcon />}
        />
      </Dropdown>
    </Tooltip>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  selectedView: selectSelectedView
});

const mapDispatchToProps = (dispatch) => ({
  setTableColumns: (columns) => dispatch(ACTIONS.setTableColumns(columns))
});

const ColumnSelector = connect(mapStateToProps, mapDispatchToProps)(ColumnSelectorComponent);

export { ColumnSelector };
